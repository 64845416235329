import React from "react";
import "./Banner.css";

export default class Banner extends React.Component {

  render() {
    return (
      <div className="banner">
        <div>Zordle</div>  
      </div>
        
    );
  }
}
